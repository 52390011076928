@font-face {
  font-family: "gilroyregular";
  src: url("../../assets/fonts/Gilroy-Regular.woff") format("woff");
}
.users-container {
  margin: 20px 26px 0px 26px;

  table{
    border: solid 1px #ccc;
  }
}
@media only screen and (min-width: 768px) {
  .users-container {
    margin: 20px 60px 0px 60px;
  }
}
