@font-face {
    font-family: "gilroyregular";
    src: url("../../assets/fonts/Gilroy-Regular.woff") format("woff");
}

body,
html {
    height: 100%;
    font-family: "gilroyregular";
    font-size: 18px;
}

.signin-wrapper {
    width: auto;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    max-width: 100vw;
}

.img-wrapper img {
    width: 100vw;
    height: 400px;
    object-fit: cover;
    object-position: 0 68%;
}

.login-form {
    text-align: left;
    background-color: white;
    border-radius: 20px;
    width: 100%;
    min-width: 320px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;

    .labels {
        margin-top: 20px;
        margin-bottom: 40px;
    }

    h1 {
        color: #6906cc;
        font-weight: bold;
        font-size: 40px;
    }

    h4 {
        font-weight: bold;
        font-size: 22px;
    }

    label {
        font-weight: bold;
        line-height: 40px;
        color: #181720;
    }

    input:not(#rememberMeCheckbox) {
        z-index: 1;
        min-width: 300px;
        width: 100%;
        height: 70px;
        box-sizing: border-box;
        background: #f4f4ff;
        border: 1px solid #d3d3e4;
        border-radius: 10px;
        padding: 0 20px;
        margin-bottom: 15px;
        line-height: 20px;
        color: #383838;

        &:focus {
            outline: none;
            ::placeholder {
                opacity: 0;
            }
        }
    }

    .form-check {
        margin-left: 0;
        padding-left: 0;

        label {
            font-weight: normal;
        }

        input {
            margin-left: 0;
            margin-right: 10px;
            background: #f4f4ff;
            border: 1px solid #d3d3e4;
            border-radius: 10px;
            width: 30px;
            height: 30px;
        }

        .form-check-input:checked[type=checkbox] {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%236e37a5' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }
    }

    .forgot-password {
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        color: #383838;
    }

    .sign-up {
        text-align: center;
        margin-top: 30px;
        font-weight: bold;
        line-height: 33px;
        font-size: 20px;

        a {
            color: #6906cc;
            padding-left: 5px;
        }
    }
}

.login-btn {
    min-width: 300px;
    width: 100%;
    height: 57px;
    background: #a754fa;
    box-shadow: 0px 8px 25px rgba(137, 20, 0, 0.02);
    border-radius: 10px;
    border: none;
    color: white;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
        background-color: #6e37a5;
    }
    &:focus {
        outline: none;
        background-color: #6e37a5;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        background-color: #3e3e3e;
        border-color: #0a0a0a;
    }
}

@media (min-width: 769px) {
    .signin-wrapper {
        display: flex;
    }
    .img-wrapper{
        min-width: 150px;
        overflow: hidden;
    }
    .img-wrapper img {
        width: auto;
        height: 100vh;
        object-fit: cover;
    }
    .login-form {
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 0;
    }
}

@media (min-width: 1064px) {    
    .rememberMe-container div:first-child {
        float : left
    }
    
    .rememberMe-container div:last-child {
        float : right
    }

    .sign-up {
        font-size: 22px;
    }
}
